/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #555;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #555;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
}

/* --------- asc styles --------- */
#header {
  // background: rgba(255, 255, 255, 0.8);
  float: none;
  a.logo {
    float: left;
    border: 0;
    width: 80%;
    max-width: 550px;
    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 40px;
    }
    span.big,
    span.small {
      display: inline;
      font-size: 1.5em;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }
    span.big {
      font-weight: 600;
      font-family: 'Roboto Slab', var(--font-family);
      line-height: 1;
      .dot-com {
        font-size: 1.1rem;
      }
    }
    span.small {
      display: none;
      margin-top: 0;
      font-size: 18px;
      letter-spacing: 0.03rem;
      font-family: var(--font-family);
      font-weight: normal;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    p {
      float: right;
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    span.phone {
      display: none;
      padding-right: 8px;
      border-right: 1px dotted #333;
    }
    .help-center {
      display: none;
    }
    a.livechat {
      float: right;
      margin: 0 0 0 10px;
      font-weight: normal;
      color: #555;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
}
#header-wrapper {
  background: #fff url(../images/header-bg-small.jpg) no-repeat 50% 50% / cover;
  #header-wrap {
    background: rgba(255, 255, 255, 0.8);
  }
}
#banner {
  h1,
  h2 {
    color: #fff;
    text-shadow: 0 1px 1px #000;
  }
  #pitch {
    background: rgba(255, 255, 255, 0.85);
    max-width: 600px;
    ul {
      font-weight: 600;
      li:before {
        color: #db151c;
      }
    }
    &.driving-records {
      max-width: 700px;
      ul {
        max-width: 450px;
      }
    }
  }
}
.spanish-reg {
  color: $link-color !important;
  font-weight: 600;
}
#points-wrap {
  background-color: #f8f8f8;
  .icon h2 {
    margin: 0 0 10px 0;
    position: relative;
  }
  .icon h2 {
    &:before {
      display: inline-block;
      margin: 0 10px;
      width: 40px;
      height: 40px;
      content: '';
      background: transparent url(../images/steps-icon.png) no-repeat 0 0 / cover;
      vertical-align: middle;
      transition: opacity 0.25s ease-out 0.25s;
      transition-delay: 0.1s;
    }
    &.step-2:before {
      background-position: -40px 0;
      transition-delay: 0.2s;
    }
    &.step-3:before {
      background-position: -80px 0;
      transition-delay: 0.3s;
    }
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
aside {
  .card {
    border-top: 3px solid #0098da;
    background: #fff url(../images/section_bg.jpg) no-repeat top right;
    &.action {
      border-top: 1px solid rgba(0, 0, 0, 0.125);
    }
  }
  .top-questions {
    background: #f9f9f9 url(../images/question-icon.png) no-repeat 95% center;
  }
}
.card {
  &.card-action {
    background: #fff url(../images/section_bg.jpg) no-repeat top right;
  }
}

// custom dropdown CTA styles
.cta-dropdown {
  width: 220px;
  vertical-align: middle;
  display: inline-block;
  span.dropdown {
    padding: 14px;
    padding-bottom: 12px;
    color: #fff;
    font-weight: 400;
    background: $cta-orange;
    background-image: linear-gradient(lighten($cta-orange, 8), darken($cta-orange, 5));
    border: 0;
    height: auto;
    line-height: 1;
    font-size: 21px;
    text-align: center;
    font-family: 'Fjalla One', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    & + .drop {
      top: 45px;
      width: 220px !important;
      li a {
        color: #333 !important;
        font-size: 0.9rem;
      }
    }
    &:after {
      display: none;
    }
    &:hover {
      background: darken($cta-orange, 7) !important;
    }
  }
  &.small {
    span.dropdown {
      padding: 14px 0 11px 0;
      font-size: 18px;
      & + .drop {
        top: 42px;
      }
    }
  }
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #banner {
    h1 {
      font-size: 30px;
    }
  }
  #points-wrap {
    .icon {
      h2:before {
        display: block;
        margin: 0 auto 10px auto;
        width: 60px;
        height: 60px;
      }
      h2.step-2:before {
        background-position: -60px 0;
      }
      h2.step-3:before {
        background-position: -120px 0;
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      display: block;
      float: left;
      max-width: 80px;
      margin-right: 15px;
    }
    span.big {
      display: inline-block;
      font-size: 2em;
    }
    span.small {
      display: inline-block;
    }
  }
  #header-wrap {
    border-top: 3px solid $theme-primary;
    #header #support {
      display: block;
    }
  }
  #banner {
    h1 {
      font-size: 40px;
    }
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  #header a.logo {
    width: 60%;
    span.small {
      font-size: 18px;
    }
  }
  #header #support {
    display: block;
    .help-center {
      display: inline-block;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    span.small {
      font-size: 18px;
    }
  }
  #header #support {
    display: block;
    span.phone {
      display: inline-block;
    }
  }
  #header-wrapper {
    background: #fff url(../images/header-bg.jpg) no-repeat 50% 50% / cover;
  }
  #banner {
    h1 {
      &.driving-records {
        font-size: 45px;
      }
    }
  }
  #points-wrap {
    .icon {
      h2 {
        // font-size: 1.2em;
        &:before {
          width: 120px;
          height: 120px;
        }
        &.step-2:before {
          background-position: -120px 0;
        }
        &.step-3:before {
          background-position: -240px 0;
        }
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
